import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const BannerContainer = styled.div`
  position: relative;
  min-height: 90px;
  text-align: center;

  img {
    margin: 0 auto;
    max-width: 970px;
  }

  &.pageTop {
    margin: 0 auto 30px;
  }

  @media ${device.s} {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'block')};
    grid-row: span 4;
    min-height: 260px;
    margin: ${({ place }) =>
      place === 'top' ? '149px auto -160px' : '60px 0 -40px 0'};

    &[type='native'] {
      grid-column: span 12;
    }

    &.row {
      min-height: 260px;
      margin: 0;
      padding: 20px 0;
    }
  }

  @media ${device.tabletPortrait} {
    min-height: 120px;
  }

  @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    min-height: 120px;
  }
`
