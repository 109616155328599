import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BannerContainer } from './banner.styled'
import isEqual from 'lodash/isEqual'
import AD from './Advert'

if (typeof window !== 'undefined') {
  window.adsConfig = window.adsConfig || {}
  window.adsConfig.limit = 20
  window.adsConfig.rendered = window.adsConfig.rendered || 0
}
class AdHolder extends Component {
  constructor(props) {
    super(props)
    this.bannerRef = React.createRef()

    this.onSlotRenderEnded = this.onSlotRenderEnded.bind(this)
  }

  static defaultProps = {
    hideOnMobile: false
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) && !isEqual(this.state, nextState)
  }

  onSlotRenderEnded = (event) => {
    if (event.isEmpty) {
      if (
        this.bannerRef &&
        this.bannerRef.current &&
        this.bannerRef.current.parentNode
      ) {
        this.toggleCard()
      }
    }
  }

  toggleCard = () => {
    const { type, rowIndex = null } = this.props

    if ((type === 'billboard' || type === 'native') && rowIndex >= 0) {
      const parentGridCardElem = document.querySelector(
        `[data-ad-container="true"][data-row-index="${rowIndex}"]`
      )
      if (parentGridCardElem) {
        parentGridCardElem.style.display = 'none'
      } else {
        if (this.bannerRef && this.bannerRef.current) {
          const root = this.bannerRef.current.parentNode
          if (root.classList.contains('inlineAdCard')) {
            root.style.display = 'none'
            root.nextElementSibling.classList.remove('ad-fallback-cell')
          }
        }
      }
    } else {
      const node = this.bannerRef.current

      if (node.parentNode.classList.contains('topBanner')) {
        node.parentNode.style.display = 'none'
      } else {
        node.style.display = 'none'
      }
    }
  }

  getType() {
    const { type } = this.props

    switch (type) {
      case 'leaderBoard': {
        return 'LEADERBOARD'
      }
      case 'billboard': {
        return 'BILLBOARD'
      }
      case 'native': {
        return 'MOBILE'
      }
      case 'native-fluid': {
        return 'NATIVE_FLUID'
      }
      default:
        return 'MOBILE'
    }
  }

  render() {
    const { page, place, adsManager } = this.props
    const format = this.getType()
    const account = adsManager.accountId || '/5910/asharq'
    const tagPath =
      page === 'Article' || page === 'Category' ? `${account}/${page}` : account

    if (
      typeof window !== 'undefined' &&
      window.adsConfig &&
      window.adsConfig.rendered === window.adsConfig.limit
    ) {
      return <div className="inlineAdCardEmptyCell" />
    }

    if (format === 'native' || format === 'MOBILE') {
      if (
        typeof window !== 'undefined' &&
        window.adsConfig &&
        window.adsConfig.rendered < window.adsConfig.limit
      ) {
        window.adsConfig.rendered = window.adsConfig.rendered + 1
      }
    }

    return (
      <BannerContainer
        className={place || 'row'}
        {...this.props}
        ref={this.bannerRef}
      >
        <AD
          type={format}
          className={place || 'row'}
          path={tagPath}
          format={format}
          canBeLower={false}
          dfpt={true}
          targeting={
            format === 'NATIVE_FLUID'
              ? { Native: ['Video', 'image', 'inread'] }
              : null
          }
          onSlotRenderEnded={this.onSlotRenderEnded}
          {...this.props}
        />
      </BannerContainer>
    )
  }
}

const mapStateToProps = ({ adsManager }) => {
  return {
    adsManager
  }
}
export default connect(mapStateToProps)(AdHolder)
